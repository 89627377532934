import React from "react";
import { useCrumbs } from "../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import useLocale, { LocaleString } from "../util/i18n";
import { type BillData, bindInventory, deliverInventory, exportInventory, getAttrValues, getInventory, getInventoryBill, getMaintenance, getWarehouse, type InventoryData, type MaintenanceData, type ModifiedData, type PricingData, reviewInventory, saveInventory, saveInventoryMaintenance, type WarehouseData } from "../api/warehouse";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBInput, MDBListGroup, MDBListGroupItem, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBTable, MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import { useRbac } from "../util/token";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import Toast from "../component/toast";
import Loading, { LoadingRef } from "../component/loading";
import Bill from "../component/bill";
import type { ListResponse } from "../api/types";
import Autocomplete from "../component/autocomplete";
import { uploadAndImport } from "../api/dataflow";
import AppendBody from "../component/appendBody";
import { CellBuilder, FileBuilder, SheetBuilder } from "excel-build";

const VersionContext = React.createContext<[number, () => void]>([0, () => { }]);
const SearchContext = React.createContext<[Record<number, Record<string, any>>, React.Dispatch<React.SetStateAction<Record<number, Record<string, any>>>>]>([{}, () => { }]);
const CompanyContext = React.createContext<[string, Array<string>]>(['', []]);

function getDate(time: number | string | Date): Date {
    let date: Date;
    if (typeof time === 'string') {
        if (/^-?\d+(?:\.\d+)?$/.test(time)) {
            time = parseFloat(time);
        } else {
            return new Date(time);
        }
    }
    date = typeof time === 'number' ? new Date(time) : time;
    return date;
}

function format(time: number | string | Date | null | undefined, display = true) {
    if (!time) {
        return '';
    }
    let date = getDate(time);
    let parts = [(date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1), (date.getDate() < 10 ? '0' : '') + date.getDate()];
    if (!display) {
        return date.getFullYear() + '-' + parts[0] + '-' + parts[1];
    }
    return parts[1] + '/' + parts[0] + '/' + date.getFullYear().toString().substring(2);
};

function getChargeData(warehouseId: number, filter: number, company: string | undefined, search: any, __: (s: string) => string) {
    return new Promise<ListResponse<InventoryData> & { config: PricingData }>((resolve) => {
        if (typeof search[warehouseId]?.finished_at !== 'undefined') {
            filter = -1;
        }
        let sort = 'main.status DESC, finished_at DESC, main.created_at DESC';
        let key = warehouseId + sort + filter + 'c:' + company + JSON.stringify(search[warehouseId] || {});
        let cache = sessionStorage.getItem(key);
        if (cache) {
            let result = JSON.parse(cache);
            resolve(result);
            let s = Object.values(search[warehouseId] || {})[0];
            if (result.data.length === 0 && s) {
                Toast.show(typeof s === 'string' ? __('No Data Matching %s').replace('%s', s) : __('No Matched Data'));
            }
        } else {
            getInventory({
                //@ts-ignore
                filter: { warehouse_id: warehouseId, info: { company }, ...(search[warehouseId] || {}), ...(filter > 0 ? { finished_at: { ngte: (new Date()).getTime() / 1000 - filter } } : {}) },
                sort, limit: 100
            }).then(response => {
                if (typeof response !== 'string') {
                    resolve(response);
                    sessionStorage.setItem(key, JSON.stringify(response));
                    let s = Object.values(search[warehouseId] || {})[0];
                    if (response.data.length === 0 && s) {
                        Toast.show(typeof s === 'string' ? __('No Data Matching %s').replace('%s', s) : __('No Matched Data'));
                    }
                }
            });
        }
    });
}

export default function Dashboard() {
    let { 0: version, 1: setVersion } = React.useState(0);
    let { 0: search, 1: setSearch } = React.useState<Record<number, Record<string, any>>>({});
    let { 0: type, 1: setType } = React.useState(0);
    let { 0: warehouses, 1: setWarehouses } = React.useState<Array<WarehouseData>>();
    let { 0: maintenances, 1: setMaintenances } = React.useState<Array<MaintenanceData>>();
    let { 0: companies, 1: setCompanies } = React.useState<Array<string>>([]);
    let { 0: company, 1: setCompany } = React.useState<string>('');
    let { 0: isGranted } = useRbac();
    let { 1: setCrumbs } = useCrumbs();
    let { 0: __ } = useLocale();
    let doChangeVersion = React.useCallback(() => {
        version && sessionStorage.clear();
        setVersion(v => v + 1);
    }, [version, setVersion]);
    let refresh = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        doChangeVersion();
    }, [doChangeVersion]);
    const loading = React.useRef<LoadingRef>(null);
    let doExport = React.useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let checked = document.querySelectorAll<HTMLElement>('.inventory.checked');
        if ((checked?.length || 0) > 0) {
            loading.current?.show(true);
            let id: Array<number> = [];
            checked?.forEach(e => e.dataset.id && id.push(parseInt(e.dataset.id)));
            exportInventory({ id }).then(blob => {
                loading.current?.hide();
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = 'export.xls';
                a.click();
            }, loading.current?.hide);
        }
    }, []);
    React.useEffect(() => {
        if (!isGranted('warehouseEntry')) {
            getWarehouse({ limit: 100 }).then(response => {
                if (typeof response !== 'string') {
                    setWarehouses(response.data);
                }
            });
        }
    }, [version, isGranted, setWarehouses]);
    React.useEffect(() => {
        setCrumbs(['Dashboard']);
        getWarehouse({ limit: 100 }).then(response => {
            if (typeof response !== 'string') {
                setWarehouses(response.data);
            }
        });
        getMaintenance({ limit: 100 }).then(response => typeof response !== 'string' && setMaintenances(response.data));
        getAttrValues({ info: 'company' }).then(response => typeof response !== 'string' && setCompanies(response));
    }, [setCrumbs, setMaintenances, setWarehouses, setCompanies]);
    return (
        <VersionContext.Provider value={[version, doChangeVersion]}>
            <SearchContext.Provider value={[search, setSearch]}>
                <CompanyContext.Provider value={[company, companies]}>
                    <Helmet prioritizeSeoTags>
                        <title>{__('Dashboard')}</title>
                    </Helmet>
                    <div className="dashboard">
                        {warehouses?.length ? (
                            warehouses?.map(warehouse => (
                                <MDBCard key={'warehouse-' + warehouse.id}>
                                    <MDBCardHeader>
                                        <h4 className="text-nowrap">
                                            {warehouse.title ? __(warehouse.title) : warehouse.address}
                                            <select className="ms-3" value={company} onChange={e => setCompany(e.target.value)}>
                                                <option value="">{__('Company')}</option>
                                                {companies.map(value => (
                                                    <option key={value} value={value}>{value}</option>
                                                ))}
                                            </select>
                                        </h4>
                                        <div className="btns">
                                            <Search warehouseId={warehouse.id} />
                                            <a href="#" onClick={refresh} title={__('Reload')}>
                                                <span className="fa fa-fw fa-refresh" />
                                            </a>
                                            {isGranted('inventoryExport') && type === 2 ? (
                                                <a href="#" onClick={doExport} title={__('Export')}>
                                                    <span className="fa fa-fw fa-file-arrow-down" />
                                                </a>
                                            ) : null}
                                            {isGranted('saveWarehouse') ? (
                                                <Link to={'/warehouse/edit?id=' + warehouse.id} title={__('Edit')}>
                                                    <span className="fa fa-fw fa-pen-to-square" />
                                                </Link>
                                            ) : null}
                                            {warehouse.phone ? (
                                                <a href={'tel:' + warehouse.phone} target="_blank" rel="noreferrer" className="call">
                                                    <span className="fa fa-fw fa-phone" />
                                                </a>
                                            ) : null}
                                            {warehouse.lat && warehouse.lng ? (
                                                <a href={'https://www.google.com/maps/@' + warehouse.lat + ',' + warehouse.lng + ',17z'} target="_blank" rel="noreferrer">
                                                    <span className="fa fa-fw fa-location-dot" />
                                                </a>
                                            ) : null}
                                        </div>
                                    </MDBCardHeader>
                                    <MDBCardBody className="p-0 p-lg-3">
                                        {type === 2 || !isGranted('warehouseEntry') ? (
                                            <Charge
                                                warehouseId={warehouse.id}
                                                maintenances={maintenances}
                                            />
                                        ) : (type === 1 ? (
                                            <StockIn warehouseId={warehouse.id} maintenances={maintenances} />
                                        ) : (
                                            <Stock warehouseId={warehouse.id} />
                                        ))}
                                    </MDBCardBody>
                                </MDBCard>
                            ))) : (
                            <div className="empty h-100 d-flex flex-column align-items-center justify-content-center">
                                <span className="fa fa-table-cells-large fa-4x mb-3" />
                                <span>{__('No Data to Display')}</span>
                            </div>
                        )}
                    </div>
                    {isGranted('warehouseEntry') ? (
                        <MDBTabs pills>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={setType.bind(null, 0)} active={type === 0}>
                                    {__('Stock')}
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={setType.bind(null, 1)} active={type === 1}>
                                    {__('Stock in')}
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={setType.bind(null, 2)} active={type === 2}>
                                    {__('Service Charge')}
                                </MDBTabsLink>
                            </MDBTabsItem>
                        </MDBTabs>
                    ) : (
                        <BindCar />
                    )}
                    <Loading ref={loading} />
                </CompanyContext.Provider>
            </SearchContext.Provider>
        </VersionContext.Provider>
    );
};

function Search(props: { warehouseId: number }) {
    let [search, setSearch] = React.useContext(SearchContext);
    let [value, setValue] = React.useState(search[props.warehouseId]?.q || '');
    let onChangeSearch = React.useCallback((e: React.MouseEvent | React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let cloned = structuredClone(search);
        cloned[props.warehouseId] = { q: value };
        setSearch(cloned);
    }, [props.warehouseId, search, setSearch, value]);
    return (
        <form onSubmit={onChangeSearch}>
            <button type="submit">
                <span className="fa fa-fw fa-magnifying-glass" />
            </button>
            <input type="search" value={value as string} onChange={e => setValue(e.target.value)} />
        </form>
    );
}

function StockIn(props: { warehouseId: number, maintenances?: Array<MaintenanceData> }) {
    let { 0: company, 1: companies } = React.useContext(CompanyContext);
    let { 0: data, 1: setData } = React.useState<Array<Partial<InventoryData>>>([{ info: { company } }]);
    let { 0: sort, 1: setSort } = React.useState('created_at DESC');
    let { 0: filter, 1: setFilter } = React.useState(parseInt(sessionStorage.getItem('time') || '604800'));
    let { 0: __ } = useLocale();
    let { 0: version, 1: setVersion } = React.useContext(VersionContext);
    let { 0: search, 1: setSearch } = React.useContext(SearchContext);
    const table = React.useRef<HTMLDivElement>(null);
    const exists = React.useRef<{ vin: Array<string>, rego: Array<string> }>({ vin: [], rego: [] });
    let onChange = React.useCallback((e: { target: { value: string, name?: string, setCustomValidity?: (msg: string) => void } }) => {
        let p = (e.target.name || '').lastIndexOf('[');
        let i = parseInt((e.target.name || '').substring(p + 1, (e.target.name || '').length - 1));
        setData(data => {
            let result = structuredClone(data);
            if ((e.target.name || '').startsWith('info[')) {
                let key = (e.target.name || '').substring(5, p - 1);
                result[i].info ??= {};
                result[i].info[key] = e.target.value;
            } else {
                let key = (e.target.name || '').substring(0, p) as keyof InventoryData;
                result[i][key] = e.target.value;
            }
            return result;
        });
    }, [setData]);
    let doSearch = React.useCallback((key: string, e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let cloned = structuredClone(search);
        if (key.startsWith('info.')) {
            let field = key.substring(5);
            if (cloned[props.warehouseId]?.info?.[field]) {
                delete cloned[props.warehouseId];
                setSearch(cloned);
            } else {
                let value = prompt();
                if (value) {
                    cloned[props.warehouseId] = { info: { [field]: { like: '%' + value.toUpperCase() + '%' } } };
                    setSearch(cloned);
                }
            }
        } else {
            if (cloned[props.warehouseId]?.[key]) {
                delete cloned[props.warehouseId];
                setSearch(cloned);
            } else {
                let value = prompt();
                if (value) {
                    cloned[props.warehouseId] = { [key]: { like: '%' + value.toUpperCase() + '%' } };
                    setSearch(cloned);
                }
            }
        }
    }, [search, setSearch, props.warehouseId]);
    let clear = React.useCallback((i: number, e: React.MouseEvent) => {
        e.preventDefault();
        setData(data => {
            let result = [...data];
            result[i] = {};
            return result;
        });
    }, [setData]);
    let remove = React.useCallback((i: number, e: React.MouseEvent) => {
        e.preventDefault();
        setData(data => {
            let result = [...data];
            result.splice(i, 1);
            return result;
        });
    }, [setData]);
    let doDelete = React.useCallback((id: number, e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (window.confirm(__('Are you sure to delete this?'))) {
            deliverInventory({ id, delete: '1' }).then(setVersion);
        }
    }, [setVersion, __]);
    let more = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setData(data => [{ info: { company } }, { info: { company } }, { info: { company } }, ...data]);
    }, [setData, company]);
    let save = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        if (table.current?.querySelector('.btn.changed')) {
            Toast.show(__('There has been changed data to confirm. Please save again after confirmation.'), 'danger');
            return;
        }
        let invalid = table.current?.querySelector<HTMLInputElement>(':invalid');
        if (invalid?.validationMessage) {
            Toast.show(invalid.validationMessage, 'danger');
            return;
        }
        let ps = [];
        for (let item of data) {
            if (item.sku?.trim()) {
                item.warehouse_id = props.warehouseId;
                item.qty = 1;
                if (item.created_at) {
                    if (typeof item.created_at === 'number' || /^-?\d+(?:\.\d+)?$/.test(item.created_at as string)) {
                        item.created_at = (new Date(parseFloat(item.created_at.toString()) * 1000)).getTime() / 1000;
                    } else if ((item.created_at as string).length === 8) {
                        item.created_at = (new Date((item.created_at as string).replace(/^(\d\d)\D(\d\d)\D(\d\d)$/, '20$3-$2-$1'))).getTime() / 1000;
                    } else {
                        item.created_at = (new Date(item.created_at as string)).getTime() / 1000;
                    }
                } else {
                    delete item.created_at;
                }
                ps.push(saveInventory(item));
            }
        }
        Promise.all(ps).then(() => {
            setData([{}]);
            setVersion();
            Toast.show(__('Data has been saved successfully.'), 'success')
        });
    }, [data, setData, props.warehouseId, __, setVersion]);
    let onSort = React.useCallback((key: string, e: React.MouseEvent | React.ChangeEvent) => {
        if (!key) {
            return;
        }
        setSort(sort => {
            if (key.includes(' ')) {
                return key;
            } else if (sort.startsWith(key)) {
                return key + ' ' + (sort.endsWith('ASC') ? 'DESC' : 'ASC');
            } else {
                return key + ' DESC';
            }
        });
    }, [setSort]);
    const doLoad = React.useRef((filter: any, cacheKey: string) => new Promise<string | ListResponse<InventoryData> & { config: PricingData }>(resolve => {
        let cache = sessionStorage.getItem(cacheKey);
        if (cache) {
            resolve(JSON.parse(cache));
        } else {
            getInventory({
                filter, sort: 'main.status DESC, main.' + sort, limit: 100
            }).then(resolve);
        }
    }));
    let upload = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        let file = e.target.files?.[0];
        if (file) {
            let reader = new FileReader();
            reader.onload = () => {
                let data = new FormData();
                data.set('mime', file.type);
                data.set('type', 'inventory');
                data.set('data', (reader.result as string).replace(/^.+base64, ?/, ''));
                data.set('warehouse_id', props.warehouseId.toString());
                uploadAndImport(data).then(() => {
                    setVersion();
                    (e.target as HTMLInputElement).value = '';
                }, (error) => {
                    Toast.show(error, 'danger');
                    (e.target as HTMLInputElement).value = '';
                });
            };
            reader.readAsDataURL(file);
        }
    }, [setVersion, props.warehouseId]);
    let doExport = React.useCallback(async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let cloned = structuredClone(search);
        cloned[props.warehouseId] = { info: { priority: 'HIGH' } };
        let response;
        try {
            response = await getChargeData(props.warehouseId, 0, undefined, cloned, __);
        } catch {
            response = { data: [], config: {} };
        }
        exportToExcel(__, props.maintenances, response.data, response.config, [__('Priority'), __('HIGH'), '', '']);
    }, [__, props.warehouseId, props.maintenances, search]);
    let template = React.useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const sheet = new SheetBuilder('sheet');
        sheet.appendThead([__('Model'), 'VIN', __('Stock in') + '(dd/mm/yy)', 'Rego', __('Company'), __('Colour'), __('Deliver Truck'), __('Priority')]);
        (new FileBuilder('Template')).addSheet(sheet).download();
    }, [__]);
    const job = React.useRef<JobListRef>(null);
    React.useEffect(() => {
        let key = props.warehouseId + sort + filter + 'c:' + company + JSON.stringify(search[props.warehouseId] || {});
        doLoad.current({ warehouse_id: props.warehouseId, info: { company }, ...(search[props.warehouseId] || {}), ...(filter > 0 ? { created_at: { ngte: (new Date()).getTime() / 1000 - filter } } : {}) }, key).then(response => {
            if (typeof response !== 'string') {
                setData([{}, ...response.data]);
                sessionStorage.setItem(key, JSON.stringify(response));
                let s = Object.values(search[props.warehouseId] || {})[0] || '';
                if (response.data.length === 0 && s) {
                    Toast.show(typeof s === 'string' ? __('No Data Matching %s').replace('%s', s) : __('No Matched Data'));
                }
            }
        });
        // let existsKey = props.warehouseId + 'created_at DESCstatus=1';
        // doLoad.current({ warehouse_id: props.warehouseId, status: 1 }, existsKey).then(response => {
        //     if (typeof response !== 'string') {
        //         sessionStorage.setItem(key, JSON.stringify(response));
        //     }
        // });
    }, [props.warehouseId, sort, version, filter, search, company, __, setData]);
    React.useEffect(() => {
        sessionStorage.setItem('time', filter.toString());
    }, [filter]);
    return (
        <>
            <div className="d-table flex-wrap gap-3" ref={table}>
                <div className="inventory head">
                    <h6 className={sort === 'id ASC' ? 'asc' : (sort === 'id DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'id')} style={{ minWidth: '6ch' }}>ID<button type="button" onClick={doSearch.bind(null, 'id')} className={search[props.warehouseId]?.info?.model ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={sort === 'info[model] ASC' ? 'asc' : (sort === 'info[model] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[model]')} style={{ minWidth: '16ch' }}>{__('Model')}<button type="button" onClick={doSearch.bind(null, 'info.model')} className={search[props.warehouseId]?.info?.model ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={sort === 'info[model_year] ASC' ? 'asc' : (sort === 'info[model_year] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[model_year]')} style={{ minWidth: 'calc(2rem + 2em)' }}><span className="text-wrap" title={__('Model Year')}>{__('Model Year')}</span><button type="button" onClick={doSearch.bind(null, 'info.model_year')} className={search[props.warehouseId]?.info?.model_year ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={sort === 'sku ASC' ? 'asc' : (sort === 'sku DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'sku')} style={{ minWidth: '18ch' }}>{__('VIN')}<button type="button" className={search[props.warehouseId]?.sku ? 'active' : ''} onClick={doSearch.bind(null, 'sku')}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={sort === 'info[engine] ASC' ? 'asc' : (sort === 'info[engine] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[engine]')}><span className="sm" title={__('Engine No.')}>{__('Engine No.')}</span><button type="button" className={search[props.warehouseId]?.engine ? 'active' : ''} onClick={doSearch.bind(null, 'info.engine')}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={sort === 'info[manufactured] ASC' ? 'asc' : (sort === 'info[manufactured] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[manufactured]')}>{__('MFG Date')}</h6>
                    <h6 className={sort === 'created_at ASC' ? 'asc' : (sort === 'created_at DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'created_at')}>{__('Stock in')}</h6>
                    <h6 className={sort === 'info[rego] ASC' ? 'asc' : (sort === 'info[rego] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[rego]')}>{__('Rego')}<button type="button" className={search[props.warehouseId]?.info?.rego ? 'active' : ''} onClick={doSearch.bind(null, 'info.rego')}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={sort === 'info[company] ASC' ? 'asc' : (sort === 'info[company] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[company]')}>{__('Company')}</h6>
                    <h6 className={sort === 'info[priority] ASC' ? 'asc' : (sort === 'info[priority] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[priority]')}>{__('Priority')}<button type="button" onClick={doExport}><span className="fa fa-file-arrow-down" /></button></h6>
                    <h6 className={sort === 'info[color] ASC' ? 'asc' : (sort === 'info[color] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[color]')}>{__('Colour')}</h6>
                    <h6 className={sort === 'info[paint] ASC' ? 'asc' : (sort === 'info[paint] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[paint]')}><span className="sm" title={__('Paint Code')}>{__('Paint Code')}</span></h6>
                    <h6 className={sort === 'info[truck] ASC' ? 'asc' : (sort === 'info[truck] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[truck]')}>{__('Deliver Truck')}</h6>
                    <h6 className={sort === 'info[odometer] ASC' ? 'asc' : (sort === 'info[odometer] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[odometer]')}>{__('Odometer')}</h6>
                    <h6>&nbsp;</h6>
                </div>
                {(() => {
                    let avai = 0, out = 0;
                    return data.map((inventory, i) => {
                        let idx = '';
                        if (inventory.id) {
                            if ((inventory.status || 0) > 0)
                                idx = (++avai).toString();
                            else
                                idx = (++out).toString();
                        }
                        return (
                            <section key={'i-' + i} className={'inventory' + (!inventory.id || (inventory.status || 0) > 0 ? '' : ' disabled')} data-idx={idx}>
                                <div title={(inventory.id || '').toString()}>{inventory.id || ''}</div>
                                <div title={inventory.info?.model || ''}>
                                    <input name={'info[model][' + i + ']'} value={inventory.info?.model || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} />
                                </div>
                                <div title={inventory.info?.model_year || ''}>
                                    <input name={'info[model_year][' + i + ']'} value={inventory.info?.model_year || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} />
                                </div>
                                <div title={inventory.sku || ''}><input name={'sku[' + i + ']'} value={inventory.sku || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} /></div>
                                <div title={inventory.info?.engine || ''}>
                                    <input name={'info[engine][' + i + ']'} value={inventory.info?.engine || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} />
                                </div>
                                <div title="">
                                    <div className="format-datetime-picker">
                                        <FormatDateTimePicker name={'info[manufactured][' + i + ']'} value={inventory.info?.manufactured || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} />
                                    </div>
                                </div>
                                <div title="">
                                    <div className="format-datetime-picker">
                                        <FormatDateTimePicker name={'created_at[' + i + ']'} value={inventory.created_at || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} />
                                    </div>
                                </div>
                                <div title={inventory.info?.rego || ''}><input name={'info[rego][' + i + ']'} value={inventory.info?.rego || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} /></div>
                                <div title={inventory.info?.company || ''}>
                                    <Autocomplete name={'info[company][' + i + ']'} value={inventory.info?.company || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} options={companies} />
                                </div>
                                <div title={__(inventory.info?.priority?.toUpperCase() || 'NORMAL')}>
                                    <select value={inventory.info?.priority?.toUpperCase() || 'NORMAL'}>
                                        <option value="">{__('NORMAL')}</option>
                                        <option value="HIGH">{__('HIGH')}</option>
                                        <option value="LOW">{__('LOW')}</option>
                                    </select>
                                </div>
                                <div title={inventory.info?.color || ''}>
                                    <input name={'info[color][' + i + ']'} value={inventory.info?.color || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} />
                                </div>
                                <div title={inventory.info?.paint || ''}>
                                    <input name={'info[paint][' + i + ']'} value={inventory.info?.paint || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} />
                                </div>
                                <div title={inventory.info?.truck || ''}><input name={'info[truck][' + i + ']'} value={inventory.info?.truck || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} /></div>
                                <div title={inventory.info?.odometer || ''}><input name={'info[odometer][' + i + ']'} value={inventory.info?.odometer || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} /></div>
                                <div title="" className="row-btns">
                                    <span>
                                        {inventory.id ? (
                                            <>
                                                <MDBBtn type="button" color="info" size="sm" title={__('Job List')} onClick={e => job.current?.show(inventory)}>J</MDBBtn>
                                                <MDBBtn type="button" color="danger" size="sm" title={__('Delete Row')} onClick={doDelete.bind(null, inventory.id)}>D</MDBBtn>
                                            </>
                                        ) : (
                                            <>
                                                <MDBBtn type="button" color="secondary" size="sm" onClick={clear.bind(null, i)} title={__('Clear Row')}>C</MDBBtn>
                                                <MDBBtn type="button" color="danger" size="sm" onClick={remove.bind(null, i)} title={__('Delete Row')}>D</MDBBtn>
                                            </>
                                        )}
                                    </span>
                                </div>
                            </section>
                        );
                    });
                })()}
            </div>
            <div className="btns justify-content-between mt-3 py-2 px-2 px-lg-0 bg-white">
                <label>
                    <span>{__('Sort by')}</span>
                    <select onChange={e => onSort(e.target.value, e)} value={sort}>
                        <option value=""></option>
                        <option value="created_at DESC">{__('Latest to Oldest')}</option>
                        <option value="created_at ASC">{__('Oldest to Latest')}</option>
                        <option value="info[model] ASC">{__('Model')}</option>
                    </select>
                </label>
                <div>
                    <MDBBtn type="button" color="secondary" onClick={template}>{__('Template')}</MDBBtn>
                    <label className="btn btn-info">
                        <span>{__('Import')}</span>
                        <input type="file" hidden onChange={upload} accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet" />
                    </label>
                    <MDBBtn type="button" color="secondary" onClick={more}>{__('Add More Rows')}</MDBBtn>
                    <MDBBtn type="button" onClick={save}>{__('Save')}</MDBBtn>
                </div>
                <MDBDropdown dropup>
                    <MDBDropdownToggle><span className="fa fa-filter" /></MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (259200 === filter ? ' active' : '')} onClick={setFilter.bind(null, 259200)}>{__('In %d Days').replace('%d', '3')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (604800 === filter ? ' active' : '')} onClick={setFilter.bind(null, 604800)}>{__('In %d Days').replace('%d', '7')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (2592000 === filter ? ' active' : '')} onClick={setFilter.bind(null, 2592000)}>{__('In %d Days').replace('%d', '30')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (0 === filter ? ' active' : '')} onClick={setFilter.bind(null, 0)}>{__('All')}</MDBBtn>
                        </MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
            <AppendBody>
                <JobList maintenances={props.maintenances} onSave={setVersion} ref={job} />
            </AppendBody>
        </>
    );
}

function Stock(props: { warehouseId: number }) {
    let { 0: inventories, 1: setInventories } = React.useState<Array<InventoryData>>();
    let { 0: sort, 1: setSort } = React.useState('created_at DESC');
    let { 0: version } = React.useContext(VersionContext);
    let { 0: search, 1: setSearch } = React.useContext(SearchContext);
    let { 0: company } = React.useContext(CompanyContext);
    let { 0: __ } = useLocale();
    let onSort = React.useCallback((key: string, e: React.MouseEvent | React.ChangeEvent) => {
        if (!key) {
            return;
        }
        setSort(sort => {
            if (key.includes(' ')) {
                return key;
            } else if (sort.startsWith(key)) {
                return key + ' ' + (sort.endsWith('ASC') ? 'DESC' : 'ASC');
            } else {
                return key + ' DESC';
            }
        });
    }, [setSort]);
    let doSearch = React.useCallback((key: string, e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let cloned = structuredClone(search);
        if (key.startsWith('info.')) {
            let field = key.substring(5);
            if (cloned[props.warehouseId]?.info?.[field]) {
                delete cloned[props.warehouseId];
                setSearch(cloned);
            } else {
                let value = prompt();
                if (value) {
                    cloned[props.warehouseId] = { info: { [field]: { like: '%' + value.toUpperCase() + '%' } } };
                    setSearch(cloned);
                }
            }
        } else {
            if (cloned[props.warehouseId]?.[key]) {
                delete cloned[props.warehouseId];
                setSearch(cloned);
            } else {
                let value = prompt();
                if (value) {
                    cloned[props.warehouseId] = { [key]: { like: '%' + value.toUpperCase() + '%' } };
                    setSearch(cloned);
                }
            }
        }
    }, [search, setSearch, props.warehouseId]);
    React.useEffect(() => {
        let key = props.warehouseId + sort + 'status=1c:' + company + JSON.stringify(search[props.warehouseId] || {});
        let cache = sessionStorage.getItem(key);
        if (cache) {
            let result = JSON.parse(cache);
            setInventories(result.data);
            let s = Object.values(search[props.warehouseId] || {})[0];
            if (result.data.length === 0 && s) {
                Toast.show(typeof s === 'string' ? __('No Data Matching %s').replace('%s', s) : __('No Matched Data'));
            }
        } else {
            getInventory({
                filter: { status: 1, warehouse_id: props.warehouseId, info: { company }, ...(search[props.warehouseId] || {}) },
                sort: 'main.' + sort, limit: 100
            }).then(response => {
                if (typeof response !== 'string') {
                    setInventories(response.data);
                    sessionStorage.setItem(key, JSON.stringify(response));
                    let s = Object.values(search[props.warehouseId] || {})[0];
                    if (response.data.length === 0 && s) {
                        Toast.show(typeof s === 'string' ? __('No Data Matching %s').replace('%s', s) : __('No Matched Data'));
                    }
                }
            });
        }
    }, [props.warehouseId, sort, version, search, company, __, setInventories]);
    return typeof inventories === 'undefined' ? (
        <div className="loading w100 text-center p-3">
            <span className="fa fa-spin fa-spinner d-block fa-3x" />
        </div>
    ) : (inventories.length === 0 ? (
        <div className="empty w100 text-center">
            <span className="fa fa-table-cells-large d-block fa-4x mb-3" />
            <span>{__('No Data to Display')}</span>
        </div>
    ) : (
        <>
            <div className="d-table flex-wrap gap-3">
                <div className="inventory head cursor">
                    <h6 className={sort === 'info[model] ASC' ? 'asc' : (sort === 'info[model] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[model]')} style={{ minWidth: '16ch' }}>{__('Model')}<button type="button" onClick={doSearch.bind(null, 'info.model')} className={search[props.warehouseId]?.info?.model ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={sort === 'info[model_year] ASC' ? 'asc' : (sort === 'info[model_year] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[model_year]')} style={{ minWidth: 'calc(2rem + 2em)' }}><span className="text-wrap" title={__('Model Year')}>{__('Model Year')}</span><button type="button" onClick={doSearch.bind(null, 'info.model_year')} className={search[props.warehouseId]?.info?.model_year ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={sort === 'sku ASC' ? 'asc' : (sort === 'sku DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'sku')} style={{ minWidth: '18ch' }}>{__('VIN')}<button type="button" className={search[props.warehouseId]?.sku ? 'active' : ''} onClick={doSearch.bind(null, 'sku')}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={sort === 'info[engine] ASC' ? 'asc' : (sort === 'info[engine] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[engine]')}>{__('Engine No.')}<button type="button" onClick={doSearch.bind(null, 'info.engine')} className={search[props.warehouseId]?.info?.engine ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={'text-center ' + (sort === 'info[manufactured] ASC' ? 'asc' : (sort === 'info[manufactured] DESC' ? 'desc' : undefined))} onClick={onSort.bind(null, 'info[manufactured]')}>{__('MFG Date')}</h6>
                    <h6 className={'text-center ' + (sort === 'created_at ASC' ? 'asc' : (sort === 'created_at DESC' ? 'desc' : undefined))} onClick={onSort.bind(null, 'created_at')}>{__('Stock in')}</h6>
                    <h6 className={sort === 'info[rego] ASC' ? 'asc' : (sort === 'info[rego] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[rego]')}>{__('Rego')}<button type="button" className={search[props.warehouseId]?.info?.rego ? 'active' : ''} onClick={doSearch.bind(null, 'info.rego')}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 className={sort === 'info[company] ASC' ? 'asc' : (sort === 'info[company] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[company]')}>{__('Company')}</h6>
                    <h6 className={sort === 'info[priority] ASC' ? 'asc' : (sort === 'info[priority] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[priority]')}>{__('Priority')}</h6>
                    <h6 className={sort === 'info[color] ASC' ? 'asc' : (sort === 'info[color] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[color]')}>{__('Colour')}</h6>
                    <h6 className={sort === 'info[paint] ASC' ? 'asc' : (sort === 'info[paint] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[paint]')}>{__('Paint Code')}</h6>
                    <h6 className={sort === 'info[odometer] ASC' ? 'asc' : (sort === 'info[odometer] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[odometer]')}>{__('Odometer')}</h6>
                </div>
                {(() => {
                    let idx = 0;
                    return inventories?.map(inventory => (
                        <section className="inventory" key={inventory.id + '-' + version} data-idx={++idx}>
                            <div title={inventory.info?.model || ''}>{inventory.info?.model || ''}</div>
                            <div title={inventory.info?.model_year || ''}>{inventory.info?.model_year || ''}</div>
                            <div title={inventory.sku || ''}>{inventory.sku || ''}</div>
                            <div title={inventory.info?.engine || ''}>{inventory.info?.engine || ''}</div>
                            <div title={format(inventory.info?.manufactured * 1000) || ''}>{format(inventory.info?.manufactured * 1000)}</div>
                            <div title={format(inventory.created_at * 1000) || ''}>{format(inventory.created_at * 1000)}</div>
                            <div title={inventory.info?.rego || ''}>{inventory.info?.rego || ''}</div>
                            <div title={inventory.info?.company || ''}>{inventory.info?.company || ''}</div>
                            <div title={inventory.info?.priority?.toUpperCase() || 'NORMAL'}><span className={'priority ' + (inventory.info?.priority?.toLowerCase() || 'normal')}>{inventory.info?.priority?.toUpperCase() || 'NORMAL'}</span></div>
                            <div title={inventory.info?.color || ''}>{inventory.info?.color || ''}</div>
                            <div title={inventory.info?.paint || ''}>{inventory.info?.paint || ''}</div>
                            <div title={inventory.info?.odometer || ''}>{inventory.info?.odometer || ''}</div>
                        </section>
                    ));
                })()}
            </div>
            <div className="btns justify-content-between mt-3 py-2 px-2 px-lg-0 bg-white">
                <label>
                    <span>{__('Sort by')}</span>
                    <select onChange={e => onSort(e.target.value, e)} value={sort}>
                        <option value=""></option>
                        <option value="created_at DESC">{__('Latest to Oldest')}</option>
                        <option value="created_at ASC">{__('Oldest to Latest')}</option>
                        <option value="info[model] ASC">{__('Model')}</option>
                    </select>
                </label>
            </div>
        </>
    ));
}

interface ChargeProps {
    warehouseId: number;
    maintenances?: Array<MaintenanceData>;
}

function Charge(props: ChargeProps) {
    let { 0: inventories, 1: setInventories } = React.useState<Array<InventoryData>>();
    let { 0: pricing, 1: setPricing } = React.useState<PricingData>({});
    let { 0: filter, 1: setFilter } = React.useState(parseInt(sessionStorage.getItem('time') || '604800'));
    let { 0: version, 1: setVersion } = React.useContext(VersionContext);
    let { 0: search, 1: setSearch } = React.useContext(SearchContext);
    let { 0: company } = React.useContext(CompanyContext);
    let { 0: __ } = useLocale();
    const finished = React.useRef<FinishedRef>(null);
    const other = React.useRef<OtherJobsRef>(null);
    const confirm = React.useRef<ConfirmRef>(null);
    const checkout = React.useRef<CheckoutRef>(null);
    const comment = React.useRef<CommentRef>(null);
    let { 0: isGranted } = useRbac();
    let showConfirm = React.useCallback((i: number, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        confirm.current?.show(inventories![i]);
    }, [inventories]);
    let showBilling = React.useCallback((i: number, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let inventory = inventories![i];
        let id = inventory.id;
        checkout.current?.show(id, inventory.status > 0 && inventory.finished_at ? () =>
            deliverInventory({ id }) : () => Toast.show(__('The vehicle has not delivered.'), 'info'));
    }, [inventories, __]);
    let showSettle = React.useCallback((i: number, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        checkout.current?.show(inventories![i].id);
    }, [inventories]);
    let showComment = React.useCallback((m: number, inventory: InventoryData, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        comment.current?.show(m, inventory);
    }, [inventories]);
    let deliver = React.useCallback((inventory: InventoryData) => {
        finished.current?.show(finished_at => {
            deliverInventory({ id: inventory.id, finished_at }).then(() => {
                setVersion();
            });
        }, [inventory.created_at * 1000, -1]);
    }, [setVersion]);
    let details = React.useCallback((inventory: InventoryData, editable: boolean) => {
        other.current?.show(inventory.id, inventory?.maintenances?.['6']?.details || [], editable);
    }, []);
    const dateFilter = React.useRef<DateFilterRef>(null);
    const selectAll = React.useRef((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let check = (e.target as HTMLElement).querySelector('input[type=checkbox]') as HTMLInputElement;
        let table = check.parentElement;
        while (table && !table?.classList.contains('d-table')) {
            table = table.parentElement;
        }
        if (check.checked) {
            check.checked = false;
            table?.querySelectorAll('.inventory:not(.head) input[type=checkbox]').forEach(box => {
                box.parentElement?.parentElement?.classList.remove('checked');
                box && ((box as HTMLInputElement).checked = false);
            });
        } else {
            check.checked = true;
            table?.querySelectorAll('.inventory:not(.head) input[type=checkbox]').forEach(box => {
                box.parentElement?.parentElement?.classList.add('checked');
                box && ((box as HTMLInputElement).checked = true);
            });
        }
    });
    let doSearch = React.useCallback((key: string, e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let cloned = structuredClone(search);
        if (key.startsWith('info.')) {
            let field = key.substring(5);
            if (cloned[props.warehouseId]?.info?.[field]) {
                delete cloned[props.warehouseId];
                setSearch(cloned);
            } else {
                let value = prompt();
                if (value) {
                    cloned[props.warehouseId] = { info: { [field]: { like: '%' + value.toUpperCase() + '%' } } };
                    setSearch(cloned);
                }
            }
        } else {
            if (cloned[props.warehouseId]?.[key]) {
                delete cloned[props.warehouseId];
                setSearch(cloned);
            } else {
                let value = prompt();
                if (value) {
                    cloned[props.warehouseId] = { [key]: { like: '%' + value.toUpperCase() + '%' } };
                    setSearch(cloned);
                }
            }
        }
    }, [search, setSearch, props.warehouseId]);
    let doExportStorage = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const sheet = new SheetBuilder('sheet');
        sheet.appendThead([__('Company'), 'VIN', 'Rego', __('Model'), __('Colour'), __('Storage From') + '(dd/mm/yy)', __('Storage To') + '(dd/mm/yy)', __('Storage Days'), __('Rate'), __('Total Charge(ex. GST)')]);
        for (let item of (inventories || [])) {
            let days = Math.ceil((new BigNumber(item?.finished_at || (new Date()).getTime() / 1000)).minus(item?.created_at || (new Date()).getTime() / 1000).div(86400).toNumber()) + 1;
            let storage = (new BigNumber(pricing?.price || 0)).times(days).toFixed(2);
            sheet.appendCustomRow([
                (new CellBuilder(item.info?.company)).build(),
                (new CellBuilder(item.sku)).build(),
                (new CellBuilder(item.info?.rego)).build(),
                (new CellBuilder(item.info?.model)).build(),
                (new CellBuilder(item.info?.color)).build(),
                (new CellBuilder(format(item.created_at * 1000))).setNumberFormat('dd/mm/yy').build(),
                (new CellBuilder(item.finished_at ? format(item.finished_at * 1000) : '')).setNumberFormat('dd/mm/yy').build(),
                (new CellBuilder(days)).build(),
                (new CellBuilder(pricing?.price)).build(),
                (new CellBuilder(storage)).build()
            ]);
        }
        (new FileBuilder('Export')).addSheet(sheet).download();        
    }, [inventories, pricing]);
    React.useEffect(() => {
        getChargeData(props.warehouseId, filter, company, search, __).then(response => {
            setInventories(response.data);
            let pricing: PricingData = {};
            for (let i in (typeof response.config === 'string' ? JSON.parse(response.config) : response.config)) {
                pricing[i as keyof PricingData] = (response.config[i as keyof PricingData] as string).startsWith('{') ?
                    JSON.parse(response.config[i as keyof PricingData] as string) : response.config[i as keyof PricingData];
            }
            setPricing(pricing);
        });
    }, [props.warehouseId, version, filter, search, setInventories, setPricing, company, __]);
    React.useEffect(() => {
        sessionStorage.setItem('time', filter.toString());
    }, [filter]);
    return typeof inventories === 'undefined' ? (
        <div className="loading w100 text-center p-3">
            <span className="fa fa-spin fa-spinner d-block fa-3x" />
        </div>
    ) : (inventories?.length === 0 ? (
        <div className="empty w100 text-center">
            <span className="fa fa-table-cells-large d-block fa-4x mb-3" />
            <span>{__('No Data to Display')}</span>
        </div>
    ) : (
        <>
            <div className="d-table flex-wrap gap-3">
                <div className="inventory head text-center">
                    {isGranted('inventoryCheck') ? (
                        <h6 className="sticky" onClick={selectAll.current}>
                            <input type="checkbox" />
                        </h6>
                    ) : null}
                    <h6>{__('Model')}<button type="button" onClick={doSearch.bind(null, 'info.model')} className={search[props.warehouseId]?.info?.model ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6 style={{ minWidth: '18ch' }}>{__('VIN')}<button type="button" onClick={doSearch.bind(null, 'sku')} className={search[props.warehouseId]?.sku ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6>{__('Rego')}<button type="button" onClick={doSearch.bind(null, 'info.rego')} className={search[props.warehouseId]?.info?.rego ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6>{__('Company')}</h6>
                    <h6>{__('Stock in')}<button type="button" onClick={e => dateFilter.current?.show('created_at', e)} className={search[props.warehouseId]?.created_at ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6>{__('Stock out')}<button type="button" onClick={e => dateFilter.current?.show('finished_at', e)} className={search[props.warehouseId]?.finished_at ? 'active' : ''}><span className="fa fa-magnifying-glass" /></button></h6>
                    <h6><span className="sm">{__('Duration')}</span></h6>
                    {props.maintenances?.map(maintenance => (
                        <h6 key={maintenance.id.toString()}>
                            <span className="sm x2">{__(maintenance.name)}</span>
                        </h6>
                    ))}
                    <h6>{__('Freight')}</h6>
                    <h6>{__('Storage')}<button type="button" onClick={doExportStorage}><span className="fa fa-file-arrow-down" /></button></h6>
                    <h6>{__('Handover')}</h6>
                    <h6 className="sticky">{__('Total')}</h6>
                    {isGranted('inventoryCheck') ? (
                        <div className="sticky row-btns"><span /></div>
                    ) : null}
                </div>
                {(() => {
                    let avai = 0, out = 0;
                    return structuredClone(inventories || []).map((inventory, i) => {
                        let idx = '';
                        if ((inventory.status) > 0)
                            idx = (++avai).toString();
                        else
                            idx = (++out).toString();
                        return (
                            <ChargeRow maintenances={props.maintenances} pricing={pricing}
                                inventory={inventory} warehouseId={props.warehouseId}
                                idx={idx}
                                key={'inventory-' + props.warehouseId + '-' + inventory.sku + i}
                                deliver={deliver.bind(null, inventory)}
                                details={details.bind(null, inventory)}
                                onApprove={showConfirm.bind(null, i)}
                                onBilling={showBilling.bind(null, i)}
                                onSettle={showSettle.bind(null, i)}
                                onComment={showComment}
                            />
                        )
                    });
                })()}
            </div>
            <div className="btns justify-content-between mt-3 p-2 bg-white">
                <div />
                <MDBDropdown dropup>
                    <MDBDropdownToggle><span className="fa fa-filter" /></MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (259200 === filter ? ' active' : '')} onClick={setFilter.bind(null, 259200)}>{__('In %d Days').replace('%d', '3')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (604800 === filter ? ' active' : '')} onClick={setFilter.bind(null, 604800)}>{__('In %d Days').replace('%d', '7')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (2592000 === filter ? ' active' : '')} onClick={setFilter.bind(null, 2592000)}>{__('In %d Days').replace('%d', '30')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (0 === filter ? ' active' : '')} onClick={setFilter.bind(null, 0)}>{__('All')}</MDBBtn>
                        </MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
            <Comment ref={comment} />
            <Confirm ref={confirm} />
            <Checkout ref={checkout} />
            <ModalFinished ref={finished} />
            <OtherJobs ref={other} />
            <DateFilter warehouseId={props.warehouseId} maintenances={props.maintenances} ref={dateFilter} />
        </>
    ));
}

interface ChargeRowProps {
    idx?: string;
    inventory: InventoryData;
    pricing: PricingData;
    warehouseId: number;
    maintenances?: Array<MaintenanceData>;
    deliver: () => void;
    details: (editable: boolean) => void;
    onApprove?: (e: React.MouseEvent<HTMLElement>) => void;
    onBilling?: (e: React.MouseEvent<HTMLElement>) => void;
    onSettle?: (e: React.MouseEvent<HTMLElement>) => void;
    onComment?: (m: number, i: InventoryData, e: React.MouseEvent<HTMLElement>) => void;
}

function ChargeRow(props: ChargeRowProps) {
    let { 0: inventory, 1: setInventory } = React.useState<InventoryData>();
    let { 1: setVersion } = React.useContext(VersionContext);
    let { 0: isGranted } = useRbac();
    let { 0: __ } = useLocale();
    let days = React.useMemo(() => {
        let now = new Date();
        let bak = (new Date(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' 00:00:00+00:00')).getTime() / 1000;
        return Math.ceil((new BigNumber(inventory?.finished_at || bak)).minus(inventory?.created_at || bak).div(86400).toNumber()) + 1;
    }, [inventory, props.pricing]);
    let storage = React.useMemo(() => {
        return (new BigNumber(props.pricing?.price || 0)).times(days).toFixed(2);
    }, [days, props.pricing]);
    let pricing = React.useMemo(() => {
        let result: { [key: string]: number } = {};
        for (let i in props.pricing) {
            if (!Number.isNaN(parseInt(i)) && props.pricing[i as keyof PricingData]) {
                let pricing = props.pricing[i as keyof PricingData] as Record<string, number>;
                for (let j in pricing) {
                    if (inventory?.info?.model?.startsWith(j)) {
                        result[i as keyof PricingData] = pricing[j];
                    }
                }
            }
        }
        return result;
    }, [inventory, props.pricing]);
    let total = React.useMemo(() => {
        let pd = new BigNumber('0');
        for (let m in (inventory?.maintenances || {})) {
            pd = pd.plus(inventory?.maintenances?.[m]?.price || 0);
        }
        return pd.plus(inventory?.freight || 0).plus(storage)
            .plus(props.pricing?.handover || 0).toFixed(2);
    }, [inventory, props.pricing, storage]);
    let editable = React.useMemo(() =>
        isGranted(['warehouseEntry', 'warehouseDelivery']) &&
            (inventory?.status || 0) > (isGranted('inventoryCheck') ? -1 : 0) ? true : undefined
        , [isGranted, inventory])
    let doCheck = React.useRef((e: React.MouseEvent<HTMLDivElement>) => {
        let o = e.target as HTMLElement;
        if (o.tagName === 'INPUT' || o.tagName === 'SELECT' || o.contentEditable === 'true') {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        while (o && !o.classList.contains('inventory')) {
            o = o.parentElement as HTMLElement;
        }
        let box = o.querySelector('input[type="checkbox"]') as HTMLInputElement;
        if (o.classList.contains('checked')) {
            o.classList.remove('checked');
            box && (box.checked = false);
        } else {
            o.classList.add('checked');
            box && (box.checked = true);
        }
    });
    let autoFill = React.useCallback((e: React.FocusEvent<HTMLDivElement>) => {
        if (e.target.dataset.price && !e.target.innerText) {
            e.target.innerText = e.target.dataset.price;
        }
    }, []);
    let onChange = React.useCallback((key: string, e: { target: HTMLDivElement | HTMLSpanElement | HTMLInputElement }) => {
        let value: number;
        if ((e.target as HTMLInputElement).type === 'date') {
            value = (new Date(parseFloat((e.target as HTMLInputElement).value) || (e.target as HTMLInputElement).value)).getTime() / 1000;
        } else if (e.target.tagName === 'INPUT') {
            value = parseFloat((e.target as HTMLInputElement).value);
        } else {
            value = parseFloat(e.target.innerText.trim());
        }
        let data: any = { ...(inventory || {}) };
        if (key.startsWith('mt-')) {
            key = key.substring(3);
            if (typeof data.maintenances === 'undefined' || Array.isArray(data.maintenances) && data.maintenances.length === 0) {
                data.maintenances = {};
            }
            if ((data.maintenances[key]?.price || 0).toString() === (value || 0).toString()) {
                return;
            }
            let qty;
            if (Number.isNaN(value)) {
                qty = 0;
                delete data.maintenances[key];
            } else {
                data.maintenances[key] = { price: qty = value, details: [] };
            }
            saveInventoryMaintenance({
                maintenance_id: parseInt(key),
                inventory_id: data.id, qty, details: [],
                status: 1, finished_at: 1
            }).then(setVersion);
        } else {
            if ((data[key] || 0).toString() === (value || 0).toString()) {
                return;
            }
            if (Number.isNaN(value)) {
                delete data[key];
            } else {
                data[key] = value;
            }
            saveInventory({ id: data.id, [key]: value }).then(setVersion);
        }
        setInventory(data);
    }, [inventory, setVersion, setInventory]);
    React.useEffect(() => {
        let data = structuredClone(props.inventory);
        let saved: Record<string, 1> = {};
        for (let history of (data.history || [])) {
            for (let key in history.modified) {
                if (!saved[key]) {
                    saved[key] = 1;
                    if (key.startsWith('mt-')) {
                        if (typeof data.maintenances === 'undefined' || Array.isArray(data.maintenances) && data.maintenances.length === 0) {
                            data.maintenances = {};
                        }
                        data.maintenances[key.substring(3)] = {
                            price: history.modified[key].qty,
                            comment: history.modified[key].comment,
                            details: history.modified[key].details || {}
                        };
                    } else if (key !== 'id') {
                        //@ts-ignore
                        data[key] = history.modified[key];
                    }
                }
            }
        }
        setInventory(data);
    }, [props.inventory, setInventory]);
    return inventory ? (
        <section className={'inventory' + ((inventory.history?.length || 0) > 0 ? ' modified' : (inventory.status <= 0 ? ' finished' : ''))} onClick={isGranted('inventoryCheck') && inventory.status <= 0 ? doCheck.current : undefined} data-id={inventory.id} data-idx={props.idx}>
            {isGranted('inventoryCheck') ? (
                <>
                    <div className="sticky" title={__('Check')}>
                        {inventory.status <= 0 ? (
                            <input type="checkbox" value={inventory.id} />
                        ) : null}
                    </div>
                </>
            ) : null}
            <div title={inventory.info?.model || ''}>{inventory.info?.model || ''}</div>
            <div title={inventory.sku || ''}>{inventory.sku || ''}</div>
            <div title={inventory.info?.rego || ''}>{inventory.info?.rego || ''}</div>
            <div title={inventory.info?.company || ''}>{inventory.info?.company || ''}</div>
            <div title={__('Stock in')}>
                <div className="format-datetime-picker">
                    <FormatDateTimePicker disabled={!editable} value={inventory.created_at} onBlur={onChange.bind(null, 'created_at')} />
                </div>
            </div>
            <div title={__('Stock out')}>
                <div className="format-datetime-picker">
                    <FormatDateTimePicker disabled={!editable} value={inventory.finished_at} onBlur={onChange.bind(null, 'finished_at')} />
                </div>
            </div>
            <div title={__('Storage Days')} className="text-center">{days || ''}</div>
            {props.maintenances?.map(maintenance => {
                if (maintenance.id == 6) {
                    return (
                        <div key={maintenance.id.toString()} title={__(maintenance.name)} className="text-center">
                            {inventory.maintenances?.[maintenance.id] ? parseFloat(inventory.maintenances[maintenance.id].price.toString()).toFixed(2) : ''}
                            <button type="button" className="details" onClick={props.details.bind(null, Boolean(editable))}><span className="fa fa-info" /></button>
                        </div>
                    );
                } else {
                    let comment = '';
                    if (inventory.maintenances?.[maintenance.id]?.comment) {
                        comment = inventory.maintenances[maintenance.id].comment || '';
                    } else {
                        for (let i in (inventory.info?.joblist || [])) {
                            if (inventory.info.joblist[i].op === 'mt-' + maintenance.id) {
                                comment = inventory.info.joblist[i].desc as string;
                            }
                        }
                    }
                    return (
                        <div key={maintenance.id?.toString()} title={__(maintenance.name)} className="text-center">
                            <span className="d-block" data-price={pricing[maintenance.id?.toString()]} contentEditable={editable} suppressContentEditableWarning
                                onFocus={inventory.maintenances?.[maintenance.id] ? undefined : autoFill}
                                onBlur={onChange.bind(null, 'mt-' + maintenance.id)}>
                                {inventory.maintenances?.[maintenance.id] ? parseFloat(inventory.maintenances[maintenance.id].price.toString()).toFixed(2) : null}
                            </span>
                            {inventory.maintenances?.[maintenance.id]?.price ? (
                                <button type="button" className="details"
                                    onClick={props.onComment?.bind(null, maintenance.id, inventory)}
                                    title={comment}>
                                    <span className="fa fa-ellipsis" />
                                </button>
                            ) : null}
                        </div>
                    );
                }
            })}
            <div title={__('Freight')} className="text-center" contentEditable={editable} suppressContentEditableWarning data-price={props.pricing?.freight?.toString()}
                onFocus={parseFloat(inventory.freight?.toString() || '0') ? undefined : autoFill}
                onBlur={onChange.bind(null, 'freight')}>{parseFloat(inventory.freight?.toString() || '0') ? parseFloat(inventory.freight!.toString()).toFixed(2) : null}</div>
            <div title={__('Storage')} className="text-center">{storage}</div>
            <div title={__('Handover')} className="text-center">{parseFloat(props.pricing?.handover?.toString() || '0').toFixed(2)}</div>
            <div title={__('Total')} className="text-center sticky">{total}</div>
            {isGranted('inventoryCheck') || isGranted('warehouseDelivery') ? (
                <div className="sticky row-btns">
                    <span>
                        {isGranted('warehouseDelivery') ? (
                            <MDBBtn onClick={props.onBilling} type="button" title={__('Billing')}>
                                B{inventory.status < 1 ? (
                                    <span className="fa fa-check" />
                                ) : null}
                            </MDBBtn>
                        ) : null}
                        {isGranted('inventoryCheck') ? ((inventory.history?.length || 0) > 0 ? (
                            <MDBBtn onClick={props.onApprove} type="button" title={__('Approval')}>A</MDBBtn>
                        ) : (
                            <MDBBtn onClick={inventory.status == 0 ? props.onSettle : undefined} type="button" title={__('Settle')}>
                                S{inventory.status < 0 ? (
                                    <span className="fa fa-check" />
                                ) : null}
                            </MDBBtn>
                        )) : null}
                    </span>
                </div>
            ) : null}
        </section>
    ) : null;
}

interface FormatDateTimePickerProps {
    value: number | string | Date | null;
    disabled?: boolean;
    name?: string;
    onBlur?: (e: { target: HTMLInputElement }) => void;
    onChange?: (e: { target: { value: string, name?: string } }) => void;
    min?: number | string | Date;
    max?: number | string | Date;
}

function FormatDateTimePicker(props: FormatDateTimePickerProps) {
    let { 0: value, 1: setValue } = React.useState<number | string | Date | null>(null);
    let { 0: changed, 1: setChanged } = React.useState(false);
    let { 0: __ } = useLocale();
    const input = React.useRef<HTMLInputElement>(null);
    let onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setChanged(true);
        props.onChange ? props.onChange(e) : setValue(new Date(e.target.value));
    }, [setValue, setChanged, props.onChange]);
    const doConfirm = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setChanged(false);
        input.current && props.onBlur?.({
            target: {
                type: input.current.type,
                name: input.current.name,
                value: value === null ? '' : (typeof value === 'object' ? value?.getTime() : value).toString()
            } as HTMLInputElement
        });
    }, [setChanged, props.onBlur, value]);
    React.useEffect(() => {
        setValue(props.value);
    }, [props.value, setValue]);
    return (
        <>
            {props.disabled ? null : (
                <input type="date" name={props.name} ref={input}
                    min={typeof props.min === 'undefined' ? undefined : format(new Date(props.min), false)}
                    max={props.max === -1 ? undefined : format(props.max ? new Date(props.max) : new Date(), false)}
                    value={value ? (format(typeof value === 'number' ? value * 1000 : (new Date(value)).getTime(), false)) : ''}
                    onChange={onChange}
                />
            )}
            {changed ? (
                <MDBBtn type="button" color="info" size="sm" className="changed" onClick={doConfirm}>{__('Confirm')}</MDBBtn>
            ) : null}
            <span title={value ? format(typeof value === 'number' ? value * 1000 : (new Date(value)).getTime()) : undefined}>
                {value ? format(typeof value === 'number' ? value * 1000 : (new Date(value)).getTime()) : ''}
            </span>
        </>
    );
}

interface CheckoutRef {
    show: (id: number, beforeLoad?: () => void) => void;
}

const Checkout = React.forwardRef<CheckoutRef>(function (_, ref) {
    let { 0: data, 1: setData } = React.useState<null | {
        warehouse: WarehouseData;
        data: InventoryData;
        bill: BillData;
    }>(null);
    let { 0: __ } = useLocale();
    let { 1: setVersion } = React.useContext(VersionContext);
    let { 0: isGranted } = useRbac();
    const afterClose = React.useRef<boolean>(false);
    const loading = React.useRef<LoadingRef>(null);
    let onSubmit = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        loading.current?.show(true);
        reviewInventory({ inventory: { id: data?.data.id, status: -1 } }).then(() => {
            loading.current?.hide();
            Toast.show(__('Data has been saved successfully.'));
            setVersion();
            setData(null);
        });
    }, [data, setVersion, setData, __]);
    let doDismiss = React.useCallback(() => {
        setData(null);
        afterClose.current && setVersion();
    }, [setData, setVersion]);
    React.useImperativeHandle(ref, () => ({
        show: async (id, beforeLoad) => {
            loading.current?.show(true);
            try {
                if (beforeLoad) {
                    afterClose.current = true;
                    await beforeLoad();
                } else {
                    afterClose.current = false;
                }
                let response = await getInventoryBill({ id });
                if (typeof response !== 'string') {
                    setData(response);
                }
                loading.current?.hide();
            } catch {
                loading.current?.hide();
            }
        }
    }), [setData]);
    return (
        <>
            <MDBModal open={Boolean(data)} onClose={doDismiss} tabIndex="-1">
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalBody className="p-2">
                            <Bill sku={undefined} {...data} />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn type="button" color="secondary" onClick={doDismiss}>{__('Cancel')}</MDBBtn>
                            {data?.data.status == 0 && isGranted('inventoryCheck') ? (
                                <MDBBtn type="button" onClick={onSubmit}>{__('Checkout')}</MDBBtn>
                            ) : null}
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <Loading ref={loading} />
        </>
    );
});

interface FinishedRef {
    show: (fn: (time: number) => void, range: [number | undefined, number | undefined]) => void;
}

const ModalFinished = React.forwardRef<FinishedRef>(function (_, ref) {
    let { 0: open, 1: setOpen } = React.useState(false);
    let { 0: value, 1: setValue } = React.useState(new Date());
    let { 0: range, 1: setRange } = React.useState<[number | undefined, number | undefined]>([undefined, undefined]);
    let { 0: __ } = useLocale();
    const modal = React.useRef<HTMLDivElement>(null);
    const submit = React.useRef<(time: number) => void>();
    let onBlur = React.useCallback((e: { target: HTMLInputElement }) => {
        setValue(new Date(e.target.value));
    }, [setValue]);
    let doSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (modal.current?.querySelector('.btn.changed')) {
            Toast.show(__('There has been changed data to confirm. Please save again after confirmation.'), 'danger');
            return;
        }
        let data = new FormData(e.target as HTMLFormElement);
        let time = data.get('finished_at') as string;
        if (time) {
            submit.current?.((new Date(time)).getTime() / 1000);
        }
        setOpen(false);
    }, [setOpen, __]);
    React.useImperativeHandle(ref, () => ({
        show: (fn, range) => {
            submit.current = fn;
            setRange(range);
            setOpen(true);
        }
    }), [setOpen, setRange]);
    return (
        <MDBModal open={open} onClose={setOpen.bind(null, false)} tabIndex="-1" modalRef={modal}>
            <MDBModalDialog centered>
                <MDBModalContent tag="form" onSubmit={doSubmit}>
                    <MDBModalHeader>
                        <MDBModalTitle>{__('Time of Delivery')}</MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody className="p-2">
                        <div className="format-datetime-picker bordered p-2">
                            <FormatDateTimePicker value={value} onBlur={onBlur} name="finished_at" min={range[0]} max={range[1]} />
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn type="button" color="secondary" onClick={setOpen.bind(null, false)}>{__('Cancel')}</MDBBtn>
                        <MDBBtn type="submit">{__('Submit')}</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
});

interface OtherJobsRef {
    show: (id: number, details: Array<[string, number]>, editable: boolean) => void
}

const OPTIONS = ['Window Tint', 'Tow Bar', 'Battery Check'];

const OtherJobs = React.forwardRef<OtherJobsRef>((_, ref) => {
    let { 0: id, 1: setId } = React.useState(0);
    let { 0: data, 1: setData } = React.useState<Map<string, number>>(new Map());
    let { 0: __ } = useLocale();
    let { 1: setVersion } = React.useContext(VersionContext);
    let { 0: editable, 1: setEditable } = React.useState(false);
    let doSave = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let qty = new BigNumber(0);
        let details: Array<[string, number]> = [];
        for (const [key, value] of data.entries()) {
            if (key.length && value > 0) {
                details.push([key, value]);
                qty = qty.plus(value);
            }
        }
        saveInventoryMaintenance({ maintenance_id: 6, inventory_id: id, qty: qty.toNumber(), details, status: 1, finished_at: 1 }).then(response => {
            Toast.show(response, 'success');
            setId(0);
            setVersion();
        });
    }, [setId, id, data, setVersion]);
    let addMore = React.useCallback(() => {
        let result = Array.from(data.entries());
        result.push([__('Item') + ' ' + result.length, 0], [__('Item') + ' ' + (result.length + 1), 0], [__('Item') + ' ' + (result.length + 2), 0]);
        setData(new Map(result));
    }, [__, data, setData]);
    let onChange = React.useCallback((key: string, e: React.ChangeEvent<HTMLInputElement> | string) => {
        let value = typeof e === 'string' ? e : e.target.value;
        data.set(key, parseFloat(value));
        setData(new Map(data.entries()));
    }, [data, setData]);
    React.useImperativeHandle(ref, () => ({
        show: (id, details, editable) => {
            setId(id);
            setEditable(editable);
            let data: typeof details = [], tmp = new Map(details);
            for (let o of OPTIONS) {
                data.push([o, tmp.get(o) || 0]);
            }
            for (const [k, v] of tmp.entries()) {
                if (!OPTIONS.includes(k)) {
                    data.push([k, v]);
                }
            }
            setData(new Map(data));
        }
    }), [setId, setData, setEditable]);
    return (
        <>
            <MDBModal open={Boolean(id)} onClose={setId.bind(null, 0)} tabIndex="-1">
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalBody className="m-0 table table-sm table-bordered" tag="table">
                            <tbody>
                                {Array.from(data.entries()).map(([key, value]) => (
                                    <tr key={'row-' + key}>
                                        <th>
                                            <MDBInput disabled={!editable} label={__('Item')} value={key || ''} />
                                        </th>
                                        <td>
                                            <MDBInput disabled={!editable} label={__('Price')} className="active" value={value} min="0" onChange={onChange.bind(null, key)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn type="button" color="info" onClick={setId.bind(null, 0)}>{__('Cancel')}</MDBBtn>
                            {editable ? (
                                <>
                                    <MDBBtn type="button" onClick={addMore}>{__('Add More Rows')}</MDBBtn>
                                    <MDBBtn type="button" onClick={doSave}>{__('Save')}</MDBBtn>
                                </>
                            ) : null}
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
});

interface ConfirmRef {
    show: (inventory: InventoryData) => void
}

const Confirm = React.forwardRef<ConfirmRef, { maintenances?: Array<MaintenanceData> }>((props, ref) => {
    let { 0: data, 1: setData } = React.useState<InventoryData>();
    let { 0: approved, 1: setApproved } = React.useState<Record<string | number, number>>({});
    let { 0: __ } = useLocale();
    let { 1: setVersion } = React.useContext(VersionContext);
    let maintenances = React.useMemo(() => {
        let result: Record<string, LocaleString> = {};
        for (let i of (props.maintenances || [])) {
            result['mt-' + i.id] = i.name;
        }
        return result;
    }, [props.maintenances]);
    let onClick = React.useCallback((key: string, id: number, e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let cloned = { ...approved };
        if (cloned[key] === id) {
            delete cloned[key];
        } else {
            cloned[key] = id;
        }
        setApproved(cloned);
    }, [approved, setApproved]);
    const loading = React.useRef<LoadingRef>(null);
    React.useImperativeHandle(ref, () => ({
        show: setData
    }), [setData]);
    let doCancel = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (Object.keys(approved).length === 0 || window.confirm(__('Unsaved data will be lost.'))) {
            setData(undefined);
            setApproved({});
        }
    }, [approved, setData, setApproved, __]);
    let doSave = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (data) {
            let ps: Array<Promise<any>> = [];
            let inventory: Partial<InventoryData> = { id: data.id };
            let history: Array<number> = [];
            let histories: Record<number, ModifiedData> = {};
            for (let h of (data.history || [])) {
                histories[h.id] = h;
            }
            for (let key in approved) {
                if (approved[key] > 0) {
                    history.push(approved[key]);
                    if (key.startsWith('mt-')) {
                        ps.push(saveInventoryMaintenance({
                            maintenance_id: parseInt(key.substring(3)),
                            inventory_id: data.id,
                            status: 1, finished_at: 1,
                            ...histories[approved[key]].modified[key]
                        }));
                    } else {
                        inventory[key as keyof InventoryData] = histories[approved[key]].modified[key];
                    }
                }
            }
            loading.current?.show(true);
            ps.push(reviewInventory({ inventory, history: history.length ? history : undefined }));
            Promise.all(ps).then(() => {
                loading.current?.hide();
                Toast.show(__('Data has been saved successfully.'));
                setVersion();
                setData(undefined);
            }, loading.current?.hide);
        }
    }, [data, approved, setData, setVersion, __]);
    return (
        <MDBModal open={Boolean(data)}>
            <Loading ref={loading} />
            <MDBModalDialog centered>
                <MDBModalContent className="approval">
                    {data?.history?.length ? (
                        <>
                            <MDBModalHeader>
                                <p className="m-0">{__('Click approve button to approve changes. Others will be rejected.')}</p>
                            </MDBModalHeader>
                            <MDBListGroup className="w-100">
                                {data.history.map(item => (
                                    <MDBListGroupItem key={item.id.toString()}>
                                        {Object.keys(item.modified).map(key => (
                                            <React.Fragment key={item.id + key}>
                                                <h6 className="author">{item.user}</h6>
                                                {key === 'id' ? null : (key.startsWith('mt-') ? (
                                                    <>
                                                        <h5 className="attr">{__(maintenances[key])}</h5>
                                                        <div className="value">
                                                            <span className="from">{parseFloat((data.maintenances?.[key.substring(3)]?.price || 0).toString())}</span>
                                                            <span className="to">{parseFloat(item.modified[key].qty.toString())}</span>
                                                        </div>
                                                    </>
                                                ) : (key.endsWith('_at') ? (
                                                    <>
                                                        <h5 className="attr">{__(key === 'created_at' ? 'Stock in' : 'Stock out')}</h5>
                                                        <div className="value">
                                                            <span className="from">{data?.[key as keyof InventoryData] ? format(data[key as keyof InventoryData] * 1000) : '-'}</span>
                                                            <span className="to">{format(item.modified[key] * 1000)}</span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h5 className="attr">{__(key[0].toUpperCase() + key.substring(1))}</h5>
                                                        <div className="value">
                                                            <span className="from">{parseFloat(data?.[key as keyof InventoryData]?.toString())}</span>
                                                            <span className="to">{parseFloat(item.modified[key].toString())}</span>
                                                        </div>
                                                    </>
                                                )))}
                                                <div className="handler">
                                                    <span>{__(approved[key] === item.id ? 'Approved' : 'Rejected')}</span>
                                                    <MDBBtn type="button" size="sm" color={approved[key] === item.id ? 'danger' : 'success'} onClick={onClick.bind(null, key, item.id)}>
                                                        <span className="fa fa-refresh" />
                                                    </MDBBtn>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </MDBListGroupItem>
                                ))}
                            </MDBListGroup>
                            <MDBModalFooter>
                                <MDBBtn type="button" color="secondary" onClick={doCancel}>{__('Cancel')}</MDBBtn>
                                <MDBBtn type="button" onClick={doSave}>{__(Object.keys(approved).length === 0 ? 'Reject All' : 'Save')}</MDBBtn>
                            </MDBModalFooter>
                        </>
                    ) : (
                        <div className="empty py-3 d-flex flex-column align-items-center justify-content-center">
                            <span>{__('The data is up-to-date.')}</span>
                            <div className="d-flex gap-3 mt-3">
                                <MDBBtn type="button" color="secondary" onClick={doCancel}>{__('Close')}</MDBBtn>
                            </div>
                        </div>
                    )}
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
});

function BindCar() {
    let { 0: open, 1: setOpen } = React.useState(false);
    let { 1: setVersion } = React.useContext(VersionContext);
    let { 0: __ } = useLocale();
    const loading = React.useRef<LoadingRef>(null);
    let onSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(false);
        loading.current?.show(false);
        let sku = (new FormData(e.target as HTMLFormElement)).get('vin') as string;
        bindInventory({ sku }).then(() => {
            loading.current?.hide();
            setVersion();
        }, () => loading.current?.hide());
    }, [setVersion, setOpen]);
    return (
        <>
            <Loading ref={loading} />
            <MDBBtn className="bind" type="button" onClick={setOpen.bind(null, true)} title={__('Bind Your Car')}>
                <span className="fa fa-car-side" />
            </MDBBtn>
            <MDBModal open={open} onClose={setOpen.bind(null, false)} tabIndex="-1">
                <MDBModalDialog centered>
                    <MDBModalContent tag="form" onSubmit={onSubmit}>
                        <MDBModalBody>
                            <MDBInput label={__('VIN')} required name="vin" />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn type="button" color="secondary" onClick={setOpen.bind(null, false)}>{__('Close')}</MDBBtn>
                            <MDBBtn type="submit">{__('Bind')}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

interface CommentRef {
    show: (maintenance_id: number, data: InventoryData) => void
}

const Comment = React.forwardRef<CommentRef>(function (_, ref) {
    let [data, setData] = React.useState<null | InventoryData & { maintenance_id: number }>(null);
    let { 1: setVersion } = React.useContext(VersionContext);
    let { 0: __ } = useLocale();
    let comment = React.useMemo(() => {
        if (data?.maintenances?.[data.maintenance_id]?.comment) {
            return data.maintenances[data.maintenance_id].comment;
        }
        for (let i in (data?.info?.joblist || [])) {
            if (data?.info.joblist[i].op === 'mt-' + data?.maintenance_id) {
                return data?.info.joblist[i].desc as string;
            }
        }
        return '';
    }, [data]);
    let onSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (!data) {
            return;
        }
        saveInventoryMaintenance({
            maintenance_id: data.maintenance_id, inventory_id: data.id,
            qty: data.maintenances?.[data.maintenance_id]?.price || 0,
            comment: data.maintenances?.[data.maintenance_id]?.comment || '', details: [], status: 1, finished_at: 1
        }).then(setVersion);
        setData(null);
    }, [data, setVersion]);
    let onChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setData(data => {
            if (!data) {
                return data;
            }
            let cloned = structuredClone(data);
            cloned.maintenances ??= {};
            cloned.maintenances[data.maintenance_id] ??= {
                price: 0, comment: '', details: []
            };
            cloned.maintenances[data.maintenance_id].comment = e.target.value;
            return cloned;
        });
    }, [setData]);
    React.useImperativeHandle(ref, () => ({
        show: (maintenance_id: number, data: InventoryData) => setData({ ...data, maintenance_id })
    }), [setData]);
    return (
        <MDBModal open={Boolean(data)} onClose={setData.bind(null, null)}>
            <MDBModalDialog centered>
                <MDBModalContent tag="form" onSubmit={onSubmit}>
                    <MDBModalHeader>{__('Comment')}</MDBModalHeader>
                    <MDBModalBody>
                        <textarea className="w-100" value={comment} onChange={onChange} />
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn type="button" color="secondary" onClick={setData.bind(null, null)}>{__('Close')}</MDBBtn>
                        <MDBBtn type="submit">{__('Save')}</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
});

interface DateFilterRef {
    show: (key: string, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

const DateFilter = React.forwardRef<DateFilterRef, { warehouseId: number, maintenances?: Array<MaintenanceData> }>(function (props, ref) {
    let { 0: key, 1: setKey } = React.useState('');
    let { 0: __ } = useLocale();
    let { 0: search, 1: setSearch } = React.useContext(SearchContext);
    let form = React.useRef<HTMLFormElement>(null);
    let onSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let data = new FormData(form.current as HTMLFormElement);
        setSearch(search => {
            let cloned = structuredClone(search);
            let value: { gte?: number, lte?: number } = {}, flag = false;
            if (data.get('gte')) {
                value.gte = (new Date((data.get('gte') as string) + ' 00:00:00+00:00')).getTime() / 1000;
                flag = true;
            }
            if (data.get('lte')) {
                value.lte = (new Date((data.get('lte') as string) + ' 00:00:00+00:00')).getTime() / 1000;
                flag = true;
            }
            if (flag) {
                cloned[props.warehouseId] = { [key]: value };
            }
            setKey('');
            return cloned;
        });
    }, [key, setKey, props.warehouseId, setSearch, __]);
    let onCancel = React.useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setSearch(search => {
            let cloned;
            if (search[props.warehouseId]?.[key]) {
                cloned = structuredClone(search);
                delete cloned[props.warehouseId];
            } else {
                cloned = search;
            }
            return cloned;
        });
        setKey('');
    }, [setKey, key, props.warehouseId, setSearch]);
    let onExport = React.useCallback(async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let data = new FormData(form.current as HTMLFormElement);
        let cloned = structuredClone(search);
        let value: { gte?: number, lte?: number } = {}, flag = false;
        if (data.get('gte')) {
            value.gte = (new Date((data.get('gte') as string) + ' 00:00:00+00:00')).getTime() / 1000;
            flag = true;
        }
        if (data.get('lte')) {
            value.lte = (new Date((data.get('lte') as string) + ' 00:00:00+00:00')).getTime() / 1000;
            flag = true;
        }
        if (flag) {
            cloned[props.warehouseId] = { [key]: value };
        }
        let response;
        try {
            response = await getChargeData(props.warehouseId, 0, undefined, cloned, __);
        } catch {
            response = { data: [], config: {} };
        }
        exportToExcel(__, props.maintenances, response.data, response.config, [__('Start Date'), data.get('gte') ? format(data.get('gte') as string) : '', __('End Date'), data.get('lte') ? format(data.get('lte') as string) : '']);
    }, [key, props.warehouseId, props.maintenances, search, __]);
    React.useImperativeHandle(ref, () => ({
        show: (key: string, e) => {
            e.stopPropagation();
            e.preventDefault();
            setKey(key);
        }
    }), [setKey]);
    return (
        <MDBModal open={Boolean(key)}>
            <MDBModalDialog centered>
                <form className="modal-content" onSubmit={onSubmit} ref={form}>
                    <MDBModalBody>
                        <MDBInput type="date" name="gte" label={__('Start Date')} />
                        <MDBInput type="date" name="lte" label={__('End Date')} className="mt-3" />
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" type="button" onClick={onCancel}>{__('Cancel')}</MDBBtn>
                        <MDBBtn type="button" onClick={onExport}>{__('Export')}</MDBBtn>
                        <MDBBtn type="submit">{__('Filter')}</MDBBtn>
                    </MDBModalFooter>
                </form>
            </MDBModalDialog>
        </MDBModal>
    );
});

function exportToExcel(__: (s: string | LocaleString) => string, maintenances: Array<MaintenanceData> | undefined, items: Array<InventoryData>, config: any, condition: [string, string, string, string]) {
    const sheet = new SheetBuilder('sheet');
    sheet.mergeCell([0, 1], [2, 1]).mergeCell([3, 1], [4, 1]).mergeCell([5, 1], [6, 1]).mergeCell([7, 1], [8, 1]).mergeCell([9, 1], [10, 1]);
    sheet.appendRow([__('Overview'), '', '', condition[0], '', condition[1], '', condition[2], '', condition[3]]);
    sheet.appendThead([__('Model'), 'VIN', ...(maintenances?.map(data => __(data.name)) || []), __('Freight'), __('Storage'), __('Handover'), __('Stock in'), 'Rego', __('Company'), __('Colour')]);
    for (let item of items) {
        let color = item.status > 0 ? '#ffffff' : '#f0f0f0';
        let days = Math.ceil((new BigNumber(item?.finished_at || (new Date()).getTime() / 1000)).minus(item?.created_at || (new Date()).getTime() / 1000).div(86400).toNumber()) + 1;
        let storage = (new BigNumber(config?.price || 0)).times(days).toFixed(2);
        sheet.appendCustomRow([
            (new CellBuilder(item.info?.model)).setBackgroundColor(color).build(),
            (new CellBuilder(item.sku)).setBackgroundColor(color).build(),
            ...(maintenances?.map(data => (new CellBuilder(parseFloat(item.maintenances?.[data.id]?.price?.toString() || '0').toFixed(2))).setBackgroundColor(color).build()) || []),
            (new CellBuilder(parseFloat(item.freight?.toString() || (config?.freight?.toString() || '0')).toFixed(2))).setBackgroundColor(color).build(),
            (new CellBuilder(storage)).setBackgroundColor(color).build(),
            (new CellBuilder(parseFloat(config?.handover?.toString() || '0').toFixed(2))).setBackgroundColor(color).build(),
            (new CellBuilder(format(item.created_at * 1000))).setBackgroundColor(color).setNumberFormat('dd/mm/yy').build(),
            (new CellBuilder(item.info?.rego)).setBackgroundColor(color).build(),
            (new CellBuilder(item.info?.company)).setBackgroundColor(color).build(),
            (new CellBuilder(item.info?.color)).setBackgroundColor(color).build()
        ]);
    }
    (new FileBuilder('Export')).addSheet(sheet).download();
}

interface JobListRef {
    show: (inventory: Partial<InventoryData>) => void
}

const JobList = React.forwardRef<JobListRef, { maintenances?: Array<MaintenanceData>, onSave?: () => void }>(function (props, ref) {
    let { 0: inventory, 1: setInventory } = React.useState<Partial<InventoryData> | null>(null);
    let { 0: __ } = useLocale();
    let { 0: lines, 1: setLines } = React.useState(3);
    const table = React.useRef<HTMLTableSectionElement>(null);
    let save = React.useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let list: Record<string, any> = {}, data = structuredClone(inventory);
        for (const { 1: elm } of (table.current?.querySelectorAll<HTMLTableCellElement>('[id][contenteditable]:not(:empty)').entries() || [])) {
            if (elm.id.includes('-')) {
                let { 0: key, 1: row } = elm.id.split('-') as [string, string];
                list[row] ??= {};
                list[row][key] = elm.innerText;
            } else {
                list[elm.id] = elm.innerText;
            }
        }
        for (const { 1: elm } of (table.current?.querySelectorAll<HTMLSelectElement>('select[id]').entries() || [])) {
            if (elm.id.includes('-') && elm.value) {
                let { 0: key, 1: row } = elm.id.split('-') as [string, string];
                list[row] ??= {};
                list[row][key] = elm.value;
            }
        }
        data!.info ??= {};
        data!.info.joblist = list;
        data && saveInventory(data);
        props.onSave?.();
    }, [inventory]);
    let savePrint = React.useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        save(e);
        window.print();
    }, [save]);
    let onChangeOp = React.useCallback((i: string, e: React.ChangeEvent<HTMLSelectElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setInventory(old => {
            let cloned = structuredClone(old);
            cloned!.info ??= {};
            cloned!.info.joblist ??= {};
            cloned!.info.joblist[i] ??= {};
            cloned!.info.joblist[i].op = e.target.value;
            return cloned;
        });
    }, [setInventory]);
    let calcLines = React.useCallback((i: number | string, e: React.FocusEvent<HTMLElement>) => {
        if (e.target.innerText.length) {
            setLines(lines => Math.max(parseInt(i.toString()) + 2, lines));
        }
    }, [setLines]);
    React.useImperativeHandle(ref, () => ({
        show: setInventory
    }), [setInventory]);
    return (
        <MDBModal className="job-list" open={Boolean(inventory)} onClose={setInventory.bind(null, null)}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalBody>
                        <MDBTable small bordered className="text-center">
                            <colgroup>
                                <col width="16.66666667%" />
                                <col width="16.66666667%" />
                                <col width="16.66666667%" />
                                <col width="16.66666667%" />
                                <col width="16.66666667%" />
                                <col width="16.66666667%" />
                            </colgroup>
                            <tbody ref={table}>
                                <tr>
                                    <th>{__('Job Card No.')}</th>
                                    <th>{__('Model Year')}</th>
                                    <th colSpan={2}>{__('Model')}</th>
                                    <th>{__('Vehicle ID')}</th>
                                    <th>{__('Date')}</th>
                                </tr>
                                <tr>
                                    <td contentEditable suppressContentEditableWarning><b>{inventory?.id}</b></td>
                                    <td contentEditable suppressContentEditableWarning></td>
                                    <td colSpan={2} contentEditable suppressContentEditableWarning>{inventory?.info.model}</td>
                                    <td contentEditable suppressContentEditableWarning><b>{inventory?.info.rego || inventory?.sku?.substring(inventory!.sku!.length - 7)}</b></td>
                                    <td contentEditable suppressContentEditableWarning>{format((inventory?.created_at || 0) * 1000)}</td>
                                </tr>
                                <tr>
                                    <th colSpan={2}>{__('VIN')}</th>
                                    <th>{__('Odometer')}</th>
                                    <th>{__('Colour')}</th>
                                    <th colSpan={2} contentEditable suppressContentEditableWarning></th>
                                </tr>
                                <tr>
                                    <td colSpan={2} contentEditable suppressContentEditableWarning>{inventory?.sku}</td>
                                    <td contentEditable suppressContentEditableWarning>{inventory?.info?.odometer}</td>
                                    <td contentEditable suppressContentEditableWarning>{inventory?.info?.color}</td>
                                    <td colSpan={2} contentEditable suppressContentEditableWarning></td>
                                </tr>
                                <tr>
                                    <th colSpan={2}>{__('Company or Company Code')}</th>
                                    <th colSpan={4} contentEditable suppressContentEditableWarning></th>
                                </tr>
                                <tr>
                                    <td colSpan={2} contentEditable suppressContentEditableWarning>{inventory?.info?.company || ''}</td>
                                    <td colSpan={4} contentEditable suppressContentEditableWarning></td>
                                </tr>
                                <tr>
                                    <th><b>{__('Job No.')}</b></th>
                                    <th><b>{__('Operation')}</b></th>
                                    <th colSpan={3}><b>{__('Description')}</b></th>
                                    <th><b>{__('Code')}</b></th>
                                </tr>
                                {Object.keys(Array(lines).fill(true)).map(i => (
                                    <tr key={'list-' + i} className="list">
                                        <td contentEditable suppressContentEditableWarning id={'no-' + i} onBlur={calcLines.bind(null, i)}>{inventory?.info?.joblist?.[i]?.['no'] || ''}</td>
                                        <td>
                                            <select id={'op-' + i} value={inventory?.info?.joblist?.[i]?.['op'] || ''} onChange={onChangeOp.bind(null, i)}>
                                                <option value=""></option>
                                                {props.maintenances?.map(value => (
                                                    <option key={'op-' + i + '-' + value.id} value={'mt-' + value.id}>{__(value.name)}</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td className="text-start text-pre-wrap" colSpan={3} contentEditable="plaintext-only" suppressContentEditableWarning id={'desc-' + i} onBlur={calcLines.bind(null, i)}>
                                            {(inventory?.info?.joblist?.[i]?.['op']?.startsWith('mt-') ? inventory?.maintenances?.[inventory.info.joblist[i]['op'].substring(3)]?.comment : '') || inventory?.info?.joblist?.[i]?.['desc'] || ''}
                                        </td>
                                        <td contentEditable suppressContentEditableWarning id={'code-' + i} onBlur={calcLines.bind(null, i)}>{inventory?.info?.joblist?.[i]?.['code'] || ''}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <th>{__('Comment')}</th>
                                    <td colSpan={5} contentEditable suppressContentEditableWarning id={'comment'}>{inventory?.info?.joblist?.comment || ''}</td>
                                </tr>
                            </tbody>
                        </MDBTable>
                    </MDBModalBody>
                    <MDBModalFooter className="print-hidden">
                        <MDBBtn type="button" color="secondary" onClick={setInventory.bind(null, null)}>{__('Close')}</MDBBtn>
                        <MDBBtn type="button" onClick={save}>{__('Save')}</MDBBtn>
                        <MDBBtn type="button" onClick={savePrint}>{__('Print')}</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
});
