import React from "react";

const Context = React.createContext<[Array<React.ReactNode>, (child: React.ReactNode) => void]>([[], () => { }]);

export function AppendBodyProvider(props: { children: React.ReactNode }) {
    let { 0: children, 1: setChildren } = React.useState<Array<React.ReactNode>>([]);
    let append = React.useCallback((child: React.ReactNode) => {
        let cloned = [...children];
        let i = cloned.push(child);
        setChildren(cloned);
        return () => setChildren(old => {
            let cloned = [...old];
            delete cloned[i - 1];
            return cloned;
        });
    }, [children, setChildren]);
    return (
        <Context.Provider value={[children, append]}>
            {props.children}
            {children}
        </Context.Provider>
    )
}

export default function AppendBody(props: { children: React.ReactNode }) {
    let { 1: append } = React.useContext(Context);
    React.useEffect(() => {
        return append(props.children);
    }, [props.children]);
    return null;
}