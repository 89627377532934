type Sort = string | { [key: string]: 'ASC' | 'DESC' } | Array<Sort>;

export interface ListParams<T> {
    filter?: T;
    sort?: Sort;
    page?: number;
    limit?: number;
}

export type ListParam<T = string | number> = T | Record<'eq' | 'ne' | 'gt' | 'gte' | 'lt' | 'lte' | 'like' | 'nlike' | 'null' | 'notnull', T> | Record<'in' | 'nin' | 'set', Array<T>>;

export interface ListResponse<T> {
    data: Array<T>;
    total: number;
    msg?: string;
}

function parseObjKey(key: string, result: Record<string, any>, value: any) {
    let start = key.indexOf('['), end = key.indexOf(']');
    let tmp = key.substring(0, start), next = key.substring(start + 1, end) + key.substring(end + 1);
    if (typeof result[tmp] === 'undefined') {
        result[tmp] = {};
    }
    if (next.includes('[')) {
        result[tmp] = parseObjKey(next, result[tmp], value);
    } else {
        typeof result[tmp] === 'undefined' ?
            result[tmp] = { [next]: value } :
            result[tmp][next] = value;
    }
    return result;
}

export function getParamsFromFormData(data: { entries: () => IterableIterator<[string, FormDataEntryValue]> }) {
    let result: Record<string, any> = {};
    for (let [k, v] of data.entries()) {
        if (k.endsWith('[]')) {
            k = k.substring(0, k.length - 2);
        }
        if (k.includes('[')) {
            result = parseObjKey(k, result, v);
        }
        if (typeof result[k] !== 'undefined') {
            if (!Array.isArray(result[k])) {
                result[k] = [result[k]];
            }
            result[k].push(v);
        } else {
            result[k] = v;
        }
    }
    return result;
}
