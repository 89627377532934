import request from "../util/client";
import { ListParams, ListResponse } from "./types";

export interface SubscriberData {
    id: number;
    customer_id: number;
    status: boolean | '1' | '0';
    email: string;
    created_at: number;
}

export function getSubscriber(params: ListParams<Partial<SubscriberData>>) {
    return request<ListResponse<SubscriberData>>('newsletter', 'GET', params);
}

interface getTemplateParams {
    id: number;
    code: string;
    language: string;
    subject: string;
    status: boolean | '1' | '0';
}

export interface TemplateData extends getTemplateParams {
    package_id: number;
    content: string;
    css: string;
    params: Array<string>;
}

export function getTemplate(params: ListParams<Partial<getTemplateParams>>) {
    return request<ListResponse<TemplateData>>('email/template', 'GET', params);
}

export function saveTemplate(params: TemplateData | FormData) {
    return request<string>('email/template', 'PUT', params);
}

export function deleteTemplate(id: number) {
    return request<string>('email/template', 'DELETE', { id });
}

interface sendEmailParams {
    params?: Record<string, any> | null;
    rcpt: Array<string | Record<string, any>>;
    scheduled_at: number;
}

export interface QueueData extends sendEmailParams {
    id: number;
    template_id: number;
    sender: string;
    status: number;
    created_at: number;
}

export function getQueue(params: ListParams<{ status?: boolean | '1' | '0' }>) {
    return request<ListResponse<QueueData>>('email/queue', 'get', params);
}

export function sendEmail(params: FormData | sendEmailParams & { code: string }) {
    return request<string>('email/queue', 'PUT', params);
}

export function cancelQueue(id: number) {
    return request<string>('email/queue', 'delete', { id });
}
